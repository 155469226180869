<template>
    <v-timeline-item
      :key="log.id"
      :color="`${color} lighten-2`"
      dark
      fill-dot
    >
      <v-card
        dark
        :color="`${color} lighten-1`"
        class="mb-4"
        outlined
      >
        <v-card-title class="body-1">{{ statusLabel }}</v-card-title>

        <v-card-text class="white body-2 grey--text py-2">

          <ul>
            <li>{{'data.time'| t}}: {{time}}</li>
            <li>
              <span>{{'ec.data.operator'| t}}: </span>
              <span>{{operatorType}}</span>
              <span v-if="operator">({{operator}})</span>
            </li>
            <li v-if="orderUid">
              {{`ec.data.${logType}.uid`| t}}: {{orderUid}}
            </li>
          </ul>
        </v-card-text>

        <v-card-actions class="white" v-if="response">
          <v-btn
            color="grey darken-2"
            outlined
            class="white--text"
            small
            @click="viewResponse"
          >
            {{'ec.action.view_response'| t}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-timeline-item>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    color: String,
    logType: String,
    log: Object,
  },
  methods: {
    viewResponse() {
      this.$apopup.base({
        width: '600px',
        title: this.$t('ec.action.view_response'),
        bodySlot: () => import('./logResponse.vue'),
        response: this.response,
      })
    },
  },
  computed: {
    statusLabel() {
      return this.$t(`ec.data.${this.logType}_status.${this.log.status}`)
    },
    time() {
      return this.$dayjs(this.log.created_at).format('YYYY/MM/DD HH:mm:ss')
    },
    operatorType() {
      return this.$t(`ec.data.operator.type.${this.log.operator_type}`)
    },
    operator() {
      return this.log.operator
    },
    orderUid() {
      return this.log.uid
    },
    response() {
      return this.log.response
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
